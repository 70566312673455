import React from "react";
import { Field } from "formik";

export const Location = () => {
    const locationData = ([
        {
            id: 1,
            location:"INDIA"
        },
        {
            id: 2,
            location:"HONGKONG"
        },
        {
            id: 3,
            location:"USA"
        },
        {
            id: 4,
            location:"UAE"
        },
        {
            id: 5,
            location:"UK"
        },
        {
            id: 6,
            location:"ISRAEL"
        },
        {
            id: 7,
            location:"BELGIUM"
        },
        {
            id: 8,
            location:"OTHER"
        }
    ]);


    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">LOCATION</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-9 d-flex flex-wrap">
                            {locationData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Location" id={`${item.location}_loc`} value={item.location}/>
                                    <div className="flourBox selectBox">
                                        <span>{item.location}</span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};