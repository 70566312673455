import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import axios from "axios"
import {NODE_API_URL} from "../../../../env_config";
import {SetCookies,RemoveCookies} from "../../../../_metronic/_helpers/Cookies";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/



function Login(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [rememberMe,setRememberMe] =useState(false);

    const initialValues = {
        email: "",
        password: "",
    };

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email id or username is required"),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
    const handelRememberMe = (e) =>{
        setRememberMe(!rememberMe)
    }
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            const finaldata ={
                email:values.email,
                password:values.password,
            }
            setTimeout(() => {
              axios({
                method: 'POST',
                url: `${NODE_API_URL}/SUPP/login`,
                headers: { 
                    'Access-Control-Allow-Origin': '*', 
                    'Content-Type': 'application/json'
                  },
                data: finaldata
              })
                .then(function (res) {
                    disableLoading();
                    // console.log(res.data)
                    if (res && res.data && res.data.msg === "This user is registered in customer portal") {
                        setSubmitting(false);
                        window.location.replace("https://www.diamondsoncall.com/login")
                    } else if (res && res.data ) {
                        const resData = JSON.stringify(res.data.data[0])
                        // console.log(res.data.data[0].id)
                        SetCookies("resData",resData)
                        SetCookies("PrimeData",resData)
                        SetCookies("PrimeId",res.data.data[0].id)
                        if (rememberMe){
                            SetCookies("ReMe","true")
                        }
                        if (!rememberMe){
                            SetCookies("ReMe","false")
                        }
                        const accessToken ="access-token-d2dff7b82f784de0aa10964abb20c1a5"
                        props.login(accessToken);
                        props.setUser(resData);
                        setSubmitting(false);
                    }
                })
                .catch((res) => {
                    // console.error(res)
                    RemoveCookies("resData")
                    disableLoading();
                    setSubmitting(false);
                    setStatus(res.response.data.msg);
                });
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="mb-5">
                <h1> Sign in to your account </h1>
                <p className="text-muted font-weight-bold">
                    Sign in with your Diamonds On Call Supplier Account
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status ? (
                    <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status} {formik.status === "Please verify your email!." ? <Link className="text-primary" to="/auth/resend-verification">Click Here to Resend verification email</Link>:""}
                        </div>
                    </div>
                ) : (
                    <div></div>
                    
                )}

                <div className="form-group fv-plugins-icon-container">
                    <label htmlFor="email">Email ID / Username</label>
                    <input
                        placeholder="Enter your email or username"
                        type="text"
                        className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                <label htmlFor="email">Password</label>
                    <input
                        placeholder="Enter Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.password}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link
                        to="/auth/forgot-password"
                        className="my-2 mr-2"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name="RememberMe"
                            checked={rememberMe}
                            onChange={(e)=> handelRememberMe(e)}
                        />
                        <span />
                        Remember me
                    </label>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                    >
                        <span>Sign In</span>
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>
                    <div id="divi" className="col-12 px-0">
                        <p className="my-2 text-right">
                            Don't have an account ?{" "}
                            <Link to="/auth/registration">
                                <strong>Create an Account</strong>
                            </Link>
                        </p>
                    </div>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
